<template>
  <div class="container">
    <pcPublicHeader />
    <div class="public-content">
      <div class="page-title">常见问题</div>
      <div class="list">
        <div class="item" :class="item.show ? 'active' : ''" v-for="(item, index) in questionList" :key="index">
          <div class="title-item">{{ index + 1 }}、{{ item.CnTitle }}</div>
          <div class="detail">
            <div class="content" v-html="item.CnContent"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapActions } from 'vuex'
import pcPublicHeader from '@/components/pcPublicHeader'

export default {
  name: "Question",
  components: {
    pcPublicHeader
  },
  data() {
    return {
      questionList: []
    }
  },
  computed: {
    ...mapState(['userInfo', 'websiteInfo'])
  },
  filters: {
    
  },
  mounted() {
    this.handleGetQuestion()
  },
  methods: {
    ...mapActions(['GetUserInfo']),
    async handleGetQuestion() {
      var res = await this.$API.questionList.post()
      // for (let index = 0; index < res.length; index++) {
      //   res[index].show = false
      // }
      this.questionList = res
    }
  }
};
</script>

<style lang="less" scoped>
.page-title {
  padding: 30px 0 22px;
  line-height: 48px;
  font-size: 32px;
  font-weight: bold;
  color: #3d3d42;
}
.list {
  padding: 40px 30px;
  background-color: #fff;
  border-radius: 8px;
  .item {
    margin-bottom: 20px;
    line-height: 21px;
    font-size: 16px;
    .title-item {
      margin-bottom: 12px;
      color: #3d3d42;
      font-weight: bold;
    }
    .detail {
      color: #9fafc1;
      /deep/img {
        max-width: 100%;
        height: auto;
      }
    }
  }
}
</style>
